<template>
  <div v-if="currentEnfant" class="edit-form">
    <h4>Enfant</h4>
    <form>
      <div class="form-group">
        <label for="title">Nom</label>
        <input type="text" class="form-control" id="nom"
          v-model="currentEnfant.nom"
        />
      </div>
      <div class="form-group">
        <label for="dateNaissance">Date Naissance</label>
        <input type="date" class="form-control" id="dateNaissance"
          v-model="currentEnfant.dateNaissance"
        />
      </div>

      <div class="form-group">
        <label for="age">Âge</label>
        <input type="number" class="form-control" id="age"
          v-model="currentEnfant.age"
        />
      </div>

      <div class="form-group">
        <label for="genre">Genre</label>
        <input type="text" class="form-control" id="genre"
          v-model="currentEnfant.genre"
        />
      </div>

      <div class="form-group">
        <label for="user">Utilisateur</label>
        <input class="form-control" id="user"
          v-model="currentEnfant.user"
        />
      </div>
    </form>


    <button class="badge badge-danger mr-2"
      @click="deleteEnfant"
    >
      Supprimer
    </button>

    <button type="submit" class="badge badge-success"
      @click="updateEnfant"
    >
      Mettre à jour
    </button>
    <p>{{ message }}</p>
  </div>

  <div v-else>
    <br />
    <p>Veuillez cliquer sur un Enfant SVP...</p>
  </div>
</template>

<script>
import EnfantDataService from "../services/EnfantDataService";

export default {
  name: "enfant",
  data() {
    return {
      currentEnfant: null,
      message: ''
    };
  },
  methods: {
    getEnfant(id) {
      EnfantDataService.get(id)
        .then(response => {
          this.currentEnfant = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateEnfant() {
      EnfantDataService.update(this.currentEnfant.id, this.currentEnfant)
        .then(response => {
          console.log(response.data);
          this.message = "Les informations de l'enfant ont été mises à jour avec succès !";
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteEnfant() {
      EnfantDataService.delete(this.currentEnfant.id)
        .then(response => {
          console.log(response.data);
          this.$router.push({ name: "enfants" });
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.message = '';
    this.getEnfant(this.$route.params.id);
  }
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>